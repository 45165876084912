
import * as HttpService from "./http.service";
import * as URL  from "./url.service";
import { getAuth, getRefreshToken, removeAuth, removeRT, setAuth } from './identity.service';
import Router  from 'next/router';

export const createUser = (user) => {
    return HttpService.postWithAuth('http://localhost:3000/users', user);
}

export const getUsers = () => {
    return HttpService.getWithAuth(`${URL}/users`);
}

export const getUser = (userId) => {
    return HttpService.getWithAuth(`http://localhost:3000/users/${userId}`);
}

export const refreshUserToken = (userId, refreshToken) => {
    return HttpService.postWithoutAuth(URL.REFRESH_USER_TOKEN, {userId,refreshToken });
  };
  
export const refreshTokenAndSetAuth = async (callback) => {
    const auth = getAuth();
    const refreshToken = getRefreshToken();
    const response = await refreshUserToken(auth.id, refreshToken);
    if (response.status) {
        const data = response.data.entity;
        const user = {
            id: data.id,
            id_token: data.id_token,
            roleId: data.roleId,
            name: data.name,
            access_token: data.access_token,
            token: data.id_token,
            role: data.Role.name,
            doctor: data.Doctor
          };
          setAuth(user);
        
       const res = await callback();
       return res;
    }
    if(!response.status && response.errorResponse.message==='Failed to get new access tokens!'){
        removeRT()
        removeAuth();
        Router.push('/login');  
    }
    
    
  };
  
  export const getUserById = (id) => {
    return HttpService.getWithAuth(URL.GET_USER_BY_ID(id))
}