import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getAuth = () => {
    const auth = cookies.get("AUTH");
    return auth;
};

export const setAuth = (authObject) => {
    cookies.set("AUTH", JSON.stringify(authObject), { 
        path: '/',          // Ensures the cookie is accessible from all paths.
        secure: true,       // Ensures the cookie is sent over HTTPS.
        sameSite: 'Lax',    // Restricts the cookie for cross-site requests.
    });
    return authObject;
};

export const removeAuth = () => {
    return cookies.remove("AUTH",  { 
        path: '/',          // Ensures the cookie is accessible from all paths.
        secure: true,       // Ensures the cookie is sent over HTTPS.
        sameSite: 'Lax',    // Restricts the cookie for cross-site requests.
    });
};

export const setRefreshToken = (refreshToken) => {
    cookies.set('RT', refreshToken, { 
        path: '/',          // Ensures the cookie is accessible from all paths.
        secure: true,       // Ensures the cookie is sent over HTTPS.
        sameSite: 'Lax',    // Restricts the cookie for cross-site requests.
    });
    return refreshToken;
}
export const getRefreshToken = () => {
    const auth = cookies.get('RT');
    return auth
}
export const removeRT = () => {
    cookies.remove('RT',  { 
        path: '/',          // Ensures the cookie is accessible from all paths.
        secure: true,       // Ensures the cookie is sent over HTTPS.
        sameSite: 'Lax',    // Restricts the cookie for cross-site requests.
    });
    return;
}

export const isAuthenticated = (user) => user != null && user.token;

export const isUnauthorizedRequest = (auth) => {
    return !auth || !isAuthenticated(JSON.parse(auth));
};
