import React from "react";
import PropTypes from "prop-types";
import Text from "../atoms/Text";
import Image from "next/image";

const IconWithText = ({
  className,
  iconPlacement,
  isActive,
  iconSrc,
  activeIconSrc,
  iconWidth,
  iconHeight,
  iconAlt,
  variant,
  textColor,
  fontSize,
  fontWeight,
  title,
  gapBetween,
  onClick,
}) => {
  return (
    <div
      className={`flex items-center max-w-fit ${gapBetween} ${className} ${
        iconPlacement === "right" && "flex-row-reverse"
      }`}
      onClick={onClick}
    >
      <Image
        src={`${isActive ? activeIconSrc : iconSrc}`}
        width={iconWidth}
        height={iconHeight}
        alt={iconAlt}
        className={className}
      />
      <Text
        variant={variant}
        fontSize={fontSize}
        textColor={textColor}
        fontWeight={fontWeight}
        children={title}
      />
    </div>
  );
};

IconWithText.defaultProps = {
  className: "gap-1.5 cursor-pointer",
  iconPlacement: "left",
  isActive: false,
  iconSrc: "",
  activeIconSrc: "",
  iconWidth: 20,
  iconHeight: 20,
  iconAlt: "icon",
  variant: "caption",
  textColor: "text-darkGray",
  fontSize: "",
  fontWeight: "font-medium",
  title: "",
  gapBetween: "gap-1.5",
  onClick: () => {},
};

IconWithText.propTypes = {
  className: PropTypes.string,
  iconPlacement: PropTypes.string,
  isActive: PropTypes.bool,
  iconSrc: PropTypes.string,
  activeIconSrc: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconAlt: PropTypes.string,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  title: PropTypes.string,
  gapBetween: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconWithText;
