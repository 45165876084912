import * as HttpService from "./http.service";
import { ALL_ADMIN_PATIENT, ALL_ADMIN_USERS, ALL_ROLES, ASSIGN_COACH_TO_PATIENT, CREATE_HC_USER, GET_ADMIN_USER_BY_ID, HC_call, SEND_HC_OTP_URL, UPDATE_USER_NUMBER } from "./url.service";

export const sendHcUserOtpService = ({ mobileNumber }) => {
    return HttpService.postWithoutAuth(SEND_HC_OTP_URL, { mobileNumber });
};

export const createHCUser = (data) => {
    return HttpService.postWithAuth(CREATE_HC_USER, data);
}

export const getAllRoles = () => {
    return HttpService.getWithAuth(ALL_ROLES, {})
}

export const getAllAdminUsers = ({ offset, limit, roleId, search, filters }) => {
    return HttpService.getWithAuth(ALL_ADMIN_USERS(offset, limit, roleId, search, filters), {})
}

export const getAdminUserById = ({ id }) => {
    return HttpService.getWithAuth(GET_ADMIN_USER_BY_ID(id))
}


export const updateAdminUser = ({ id, body }) => {
    return HttpService.putWithAuth(GET_ADMIN_USER_BY_ID(id), body)
}

export const deleteAdminUser = ({ id }) => {
    return HttpService.deleteWithAuth(GET_ADMIN_USER_BY_ID(id))
}

export const getAllAdminPatientUsers = ({ offset, limit, search, filters }) => {
    return HttpService.getWithAuth(ALL_ADMIN_PATIENT(offset, limit, search, filters), {})
}

export const updatePatient = ({ patientId, body }) => {
    return HttpService.putWithAuth(ASSIGN_COACH_TO_PATIENT(patientId), body)
}

export const callToCustomer = (body) => {
    return HttpService.postWithAuth(HC_call(), body)
}

export const updateUserNumber = ({ id, body }) => {
    return HttpService.putWithAuth(UPDATE_USER_NUMBER(id), body)
}
