import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DDSidebar from "organisms/DDSidebar";
import { Providers } from "utils/Provider";
import { useDrSidebarContext } from "components/context/doctorSideBarContext";


const DDLayout = ({ children }) => {
  const { isSidebarOpen, toggleSidebar } = useDrSidebarContext()


  return (
    <div>
   
      <div
        className={`min-h-full bg-grey3 pb-6 transition-all ${isSidebarOpen ? "px-4 lg:pl-[240px]" : "px-4 lg:pl-[142px]"
          }`}
      >
        <div className="max-w-[968px] w-full mx-auto pt-[230px] lg:pt-0">
          <Providers>{children}</Providers>
        </div>
      </div>
    </div>
  );
};
DDLayout.propTypes = {
  children: PropTypes.node,
};
export default DDLayout;
