import DDSidebarListItems from "../molecules/DDSidebarListItems";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Icon from "atoms/Icon";
import { getAuth } from "../services/identity.service";

const DoctorSidebar = ({ className, isSidebarOpen, toggleSidebar }) => {
  const auth = getAuth();
  const router = useRouter();

  const sideBarDetails = 
  auth?.role === "doctor" ?
      [
        // {
        //   menuActive: router.pathname.startsWith("/doctor-panel/dashboard"),
        //   menuItem: "Dashboard",
        //   menuIconNormalSrc: "/images/icons/dashboard-b.svg",
        //   menuIconActiveSrc: "/images/icons/dashboard-g.svg",
        //   menuIconAlt: "Patients",
        //   onClick: () => changeRoute("/doctor-panel/dashboard"),
        // },
    {
      menuActive: router.pathname.startsWith("/doctor-panel/patients"),
      menuItem: "Patients",
      menuIconNormalSrc: "/images/icons/patient-b.svg",
      menuIconActiveSrc: "/images/icons/patient-g.svg",
      menuIconAlt: "Patients",
      onClick: () => changeRoute("/doctor-panel/patients"),
    },
    {
      menuActive: router.pathname.startsWith("/doctor-panel/profile"),
      menuItem: "Profile",
      menuIconNormalSrc: "/images/icons/profile-b.svg",
      menuIconActiveSrc: "/images/icons/profile-g.svg",
      menuIconAlt: "Profile",
      onClick: () => changeRoute("/doctor-panel/profile"),
    },
  ]:
  [
    {
      menuActive: router.pathname.startsWith("/doctor-panel/doctors"),
      menuItem: "Doctors",
      menuIconNormalSrc: "/images/icons/profile-b.svg",
      menuIconActiveSrc: "/images/icons/profile-g.svg",
      menuIconAlt: "Patients",
      onClick: () => changeRoute("/doctor-panel/doctors"),
    },
    {
      menuActive: router.pathname.startsWith("/doctor-panel/profile"),
      menuItem: "Profile",
      menuIconNormalSrc: "/images/icons/profile-b.svg",
      menuIconActiveSrc: "/images/icons/profile-g.svg",
      menuIconAlt: "Profile",
      onClick: () => changeRoute("/doctor-panel/profile"),
    },
  ];

  const changeRoute = (route) => {
    const initialSidebarState = window.matchMedia('(max-width: 768px)').matches
    if (initialSidebarState) {
      toggleSidebar()
    }
    router.push(route)
  }
  return (
    <>
      <div
        className={`fixed bg-white lg:bg-grey2 lg:h-full z-40 py-3 px-6 lg:pt-8 lg:pl-10 border-r border-grey1 transition-all shadow-ddSidebar lg:shadow-none ${isSidebarOpen
          ? "lg:w-[224px] -top-full lg:top-0 -left-full lg:left-0"
          : "w-full lg:w-[126px] top-[205px] lg:top-0 left-auto lg:left-0 -ml-4 lg:ml-0"
          } ${className}}`}
      >
        <div className="hidden lg:block">
          <Icon
            className="cursor-pointer"
            iconSrc={`${isSidebarOpen
              ? "/images/icons/hamburger.svg"
              : "/images/icons/hamburger.svg"
              }`}
            iconWidth="32"
            iconHeight="32"
            onClick={toggleSidebar}
          />
        </div>
       

        <ul className="lg:mt-9">
          {sideBarDetails.map((sidebar, idx) => (
            <DDSidebarListItems
              key={idx}
              menuActive={sidebar.menuActive}
              menuItem={sidebar.menuItem}
              menuIconNormalSrc={sidebar.menuIconNormalSrc}
              menuIconActiveSrc={sidebar.menuIconActiveSrc}
              menuIconAlt={sidebar.menuIconAlt}
              onClick={sidebar.onClick}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </ul>
      </div>
      {!isSidebarOpen && (
        <div className="fixed left-0 top-0 bg-white w-full h-screen opacity-60 z-[31] lg:hidden"></div>
      )}
    </>
  );
};

DoctorSidebar.defaultProps = {
  className: "",
  isSidebarOpen: true,
  toggleSidebar: () => { },
};

DoctorSidebar.propTypes = {
  className: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default DoctorSidebar;
