import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";

const SidebarListItems = ({
  menuItem,
  menuIconNormalSrc,
  menuIconActiveSrc,
  menuIconAlt,
  menuActive,
  isIconAvail,
  onClick,
  isSidebarOpen,
}) => {
  return (
    <li
      className={`flex items-center  p-4 mb-4 cursor-pointer ${
        menuActive && "bg-secondary-900"
      } ${isSidebarOpen ? "rounded-l-full" : "rounded-2xl"}`}
      onClick={onClick}
    >
      {isIconAvail && (
        <div className="flex items-center shrink-0">
          {!menuActive ? (
            <Image
              src={menuIconNormalSrc}
              width={24}
              height={24}
              alt={menuIconAlt}
            />
          ) : (
            <Image
              src={menuIconActiveSrc}
              width={24}
              height={24}
              alt={menuIconAlt}
            />
          )}
        </div>
      )}
      {isSidebarOpen && (
        <div
          className={`text-base ml-4  ${
            menuActive ? "text-white" : "text-darkGray"
          }`}
        >
          {menuItem}
        </div>
      )}
    </li>
  );
};

SidebarListItems.defaultProps = {
  menuItem: "",
  menuIconNormalSrc: "",
  menuIconActiveSrc: "",
  menuIconAlt: "",
  menuActive: false,
  isIconAvail: true,
  onClick: () => {},
  isSidebarOpen: true,
};

SidebarListItems.propTypes = {
  menuItem: PropTypes.string,
  menuIconNormalSrc: PropTypes.string,
  menuIconActiveSrc: PropTypes.string,
  menuIconAlt: PropTypes.string,
  menuActive: PropTypes.bool,
  isIconAvail: PropTypes.bool,
  onClick: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default SidebarListItems;
