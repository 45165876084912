import React from "react";
import PropTypes from "prop-types";
import { Providers } from "utils/Provider";

const EmptyLayout = ({ children }) => {
    return <div className="page-content">
        <Providers>
            {children}
        </Providers>
    </div>;
};

EmptyLayout.propTypes = {
    children: PropTypes.node,
};

export default EmptyLayout;
