import { getAuth, isAuthenticated } from "services/identity.service";
import { isProtected } from "./Security";
import hasPermission from "lib/permission";

const isBrowser = () => typeof window !== "undefined";

const WithAuth = ({ router, children }) => {
  const auth = getAuth();
  let url = (auth?.role !== "doctor" || auth?.role !== "kam") ? "/" : "/doctor-panel/patients"
  if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
    router.replace("/login");
    // return null;
  }
  if (isBrowser() && isAuthenticated(auth) && !isProtected(router.pathname)) {
    router.replace(url);
    // return null;
  }

  if (isBrowser() && isAuthenticated(auth) && isProtected(router.pathname) && !hasPermission(router.pathname, auth)) {
    router.replace(url);
    // return null;
  }

  return children;
};

export default WithAuth;
