import * as HttpService from "./http.service";
import { GET_UNREAD_FLAREUP_COUNT, GET_UNREAD_REDNESS_COUNT, GET_SCHEDULED_CALL_COUNT, GET_PATIENT_COUNT, GET_LEAVE_REQUEST_COUNT, UPDATE_PATIENT_COUNT } from './url.service'

export const getUnreadFlareUpCount = ({ hcId, status, forIbs = false }) => {
    return HttpService.getWithAuth(GET_UNREAD_FLAREUP_COUNT({ hcId, status, forIbs }));
};

export const getUnreadRednessCount = ({ hcId, status }) => {
    return HttpService.getWithAuth(GET_UNREAD_REDNESS_COUNT({ hcId, status }));
};

export const getScheduledCallCount = ({ scheduledBy, callStatus }) => {
    return HttpService.getWithAuth(GET_SCHEDULED_CALL_COUNT({ scheduledBy, callStatus }));
};

export const getLeaveRequestCount = ({ status }) => {
    return HttpService.getWithAuth(GET_LEAVE_REQUEST_COUNT({ status }))
}

export const getPatientCount = ({ type, hcId, status }) => {
    return HttpService.getWithAuth(GET_PATIENT_COUNT({ type, hcId, status }))
}

export const updatePatientCount = ({ type, patientId, status }) => {
    return HttpService.putWithAuth(UPDATE_PATIENT_COUNT({ patientId }), { type, status });
}