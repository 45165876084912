import * as HttpService from "./http.service";
import { GET_CRM_PATIENTS_URL, GET_NUTRITIONISTS_PATIENTS, GET_UV_COACH_FLAREUPS } from './url.service'

export const getNutritionistsPatients = ({ limit, offset, id, onlyFlareup, search = "",filters }) => {
  return HttpService.getWithAuth(GET_NUTRITIONISTS_PATIENTS(offset, limit, id, onlyFlareup, search, filters));
};

export const getUvCoachFlareups = ({ limit, offset, id, onlyFlareup }) => {
  return HttpService.getWithAuth(GET_UV_COACH_FLAREUPS(offset, limit, id, onlyFlareup));
};

export const getCrmPatients = ({ offset, limit, id, search, filters }) => {
  return HttpService.getWithAuth(GET_CRM_PATIENTS_URL(offset, limit, id, search, filters));
}
