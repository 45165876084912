import "../styles/globals.css";
import { FlagsmithProvider } from "flagsmith/react";
import flagsmith from "flagsmith/isomorphic";
import { getLayoutConfig } from "../components/Layout/LayoutConfiguration";
import WithAuth from "components/Auth/WithAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getAuth } from 'services/identity.service';
import Head from 'next/head';
import { DoctorSidebarProvider } from "components/context/doctorSideBarContext";

function MyApp({ Component, pageProps, flagsmithState, router }) {
  const [isClient, setIsClient] = useState(false)
  const auth = getAuth()

  useEffect(() => {
    setIsClient(true)
  }, [])

  let layoutConfig = getLayoutConfig(router.pathname);
  const { Layout: FetchedLayout } = layoutConfig;
  return isClient && (
    <>
    <Head>
        <script defer async client-code="KA-518940-2" src="https://static.katalon.com/libs/traffic-agent/v1/traffic-agent.min.js"></script>
    </Head>
    <QueryClientProvider client={new QueryClient()}>
      <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
        <WithAuth router={router}>
          <DoctorSidebarProvider >
            <FetchedLayout children={<Component {...pageProps} />} />
          </DoctorSidebarProvider>
        </WithAuth>
      </FlagsmithProvider>
    </QueryClientProvider>
    </>
  );
}

MyApp.getInitialProps = async () => {
  await flagsmith.init({
    environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY,
  });
  return { flagsmithState: flagsmith.getState() };
};

export default MyApp;
