import React, { useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "organisms/Sidebar";
import { Providers } from "utils/Provider";

const SideBarLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`min-h-screen bg-bodyBg w-full overflow-y-auto p-12 pt-24 transition-all ${
          isSidebarOpen ? "pl-[320px]" : "pl-[140px]"
        }`}
        isSidebarOpen={isSidebarOpen}
      >
        <Providers>{children}</Providers>
      </div>
    </div>
  );
};
SideBarLayout.propTypes = {
  children: PropTypes.node,
};
export default SideBarLayout;
