import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getNutritionistsPatients, getUvCoachFlareups } from './nutritionist.service';


export const useNutritionistsPatients = (params) => {
    return useQuery(['nutritionist-patients', params], () => getNutritionistsPatients(params));
}

export const useUvCoachFlareups = (params) => {
    return useQuery(['uv-coach-flareups', params], () => getUvCoachFlareups(params));
}