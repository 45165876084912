import { createContext, useContext, useEffect, useState } from 'react';

const drSidebarContext = createContext()


export function useDrSidebarContext() {
    return useContext(drSidebarContext)
}

export const DoctorSidebarProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <drSidebarContext.Provider value={{ toggleSidebar, isSidebarOpen, }}>
            {children}
        </drSidebarContext.Provider>
    )
}

